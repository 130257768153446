import { useContext, useState, useEffect, useMemo } from "react";

//Context
import { ImagesContext } from "../App";

const ImagesSlideShow = ({className}) =>{
    const { images } = useContext(ImagesContext);
    const [displayImages, setDisplayImages] = useState([]);
    const [currentImage, setCurrentImage] = useState(images[0]);

    useEffect(() =>{
        setDisplayImages([...images]);
    }, [images]);
    

    useEffect(() =>{
        const intervalId = setInterval(() => {
            let tempImages = [...displayImages];
            const tempImage = tempImages[tempImages.length - 1];
            tempImages.unshift(tempImage); //Adds the image from the end of the array to the front
            tempImages.pop(); //Pops the last iamge from the back
            setCurrentImage(tempImages[0]);
            setDisplayImages(tempImages);
        }, 3500);   //Transitions every 3.5 seconds

        return () => clearInterval(intervalId); // Cleanup function to clear the interval
    }, [images, displayImages]);

    return(
        <div className={`imagesSlideShow w-3/4 flex flex-col justify-center md:w-1/2 ${className}`} >
            <img loading="lazy" className="shadow-md" src={currentImage.imgUrl}></img>
            <h2 className="font-nono">{currentImage.name}</h2>
        </div>
    );
}

export default ImagesSlideShow;