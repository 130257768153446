
//Components
import CardWrapper from "../mill-comps/components/CardWrapper";

const PreviewItem = ({item, location}) =>{
    return (
        <CardWrapper to={location} className="previewItem shadow-md w-52 h-52 flex flex-col items-center justify-between gap-3 overflow-hidden" style={{borderRadius: "15px"}}>
            <img loading="lazy" className="max-h-36" src={item?.imgUrl}></img>
            <h3 className="text-center font-nono font-medium">{item?.name}</h3>
        </CardWrapper>
    );
}

export default PreviewItem;