
//Components
import CardWrapper from "../mill-comps/components/CardWrapper";

const ProjectMenuBtn = ({className, refer, children, setCurrentSelection, value, currentSelection}) =>{

    const handleClick = () =>{
        setCurrentSelection({ref: refer, val: value});
    }

    return(
        <CardWrapper className={`transition-all !bg-appleBlue hover:!bg-appleLightBlue shadow-md duration-300 font-nono text-appleWhite ${(currentSelection.ref === refer) ? "!bg-appleUltraLightBlue" : ""} ${className}`}>
            <button onClick={handleClick} ref={refer}>{children}</button>
        </CardWrapper>
    );
}

export default ProjectMenuBtn;