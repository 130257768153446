import { useState, useEffect, useContext } from 'react';

//Components
import AppleHeader from './AppleHeader';
import VerticalProjects from './Projects/VerticalProjects';
import ProjectsViewMenu from './Projects/ProjectsViewMenu';

//Context
import { ProjectsContext } from '../App';

const Projects = () =>{
    const filterEnum = {
        all: 0,
        frontEnd: 1,
        backEnd: 2,
        misc: 3
    }

    useEffect(() =>{
        document.title = "Clark's Portfolio | Projects";
    }, []);

    const {projects} = useContext(ProjectsContext);
    const [displayProjects, setDisplayProjects] = useState(null);
    //This state manages what project type will be displayed on the screen
    const [currentFilter, setCurrentFilter] = useState(filterEnum.all);

    useEffect(() =>{
        if(projects) setDisplayProjects(projects);
    }, [projects]);

    useEffect(() =>{
        if(currentFilter){
            //Checks what filter is being used
            switch(currentFilter.val){
                case filterEnum.all:
                    console.log("All Filter");
                    setDisplayProjects([...projects]);   //Just gives it the base filter
                    break;
                case filterEnum.frontEnd:
                    console.log("Front-end Filter");
                    setDisplayProjects(projects.filter((project) => project.category === "front-end" || project.category === "full-stack"));
                    break;
                case filterEnum.backEnd:
                    console.log("Back-end Filter");
                    setDisplayProjects(projects.filter((project) => project.category === "back-end" || project.category === "full-stack"));
                    break;
                case filterEnum.misc:
                    console.log("Misc Filter");
                    setDisplayProjects(projects.filter((project) => project.category === "misc"));
                    break;
            }
        }
    }, [currentFilter]);

    return(
        <div className="projects col-flex-center flex-wrap py-5">
            <AppleHeader className="mb-5" text1="Some of " text2="my best work" />
            <ProjectsViewMenu className="mb-5" filterEnum={filterEnum} setCurrentFilter={setCurrentFilter}/>
            <VerticalProjects projects={displayProjects} />
        </div>
    );
}

export default Projects;