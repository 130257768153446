import { useMediaQuery } from 'react-responsive'

const FooterRow = ({headerText, children, className, contactActive}) =>{
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    return(
        <div className={`footerColumn w-fit flex flex-row justify-center items-center gap-5 grow ${className}`}>
            {!contactActive&&
                <h3 className="text-xl lg:text-2xl text-apple-light-gradient cursor-pointer">{headerText}</h3>
            }
            
            {
                isTabletOrMobile ?
                    <>
                        {contactActive&&
                            <ul className="flex flex-col items-center text-apple-white text-sm md:text-md lg:text-lg gap-5 text-center">
                            {
                                children
                            }
                            </ul>
                        }
                    </>
                :
                    <>
                        {contactActive&&
                            <ul className="flex flex-row text-apple-white text-sm md:text-md lg:text-lg gap-5 text-center">
                            {
                                children
                            }
                            </ul>
                        }
                    </>
            }
        </div>
    );
}

export default FooterRow;