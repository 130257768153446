import { useNavigate } from "react-router-dom";
import { ShakeLittle } from "reshake";

const NavItem = ({text, icon, location, href, shake, children}) =>{
    const navigate = useNavigate();


    return(
        <>
            {
                shake ? 
                
                <ShakeLittle h={1} v={0} r={0.5}>
                    {
                        href ? 
                        <li className="navItem text-xl md:text-3xl hover:cursor-pointer"><a href={href} target="_blank">{children}</a></li>
                        :
                        <li onClick={() => navigate(location)} className="navItem text-xl md:text-3xl hover:cursor-pointer">{children}</li>
                    }
                </ShakeLittle>
                :
                <>
                    {
                        href ? 
                        <li className="navItem text-xl md:text-3xl hover:cursor-pointer"><a href={href} target="_blank">{children}</a></li>
                        :
                        <li onClick={() => navigate(location)} className="navItem text-xl md:text-3xl hover:cursor-pointer">{children}</li>
                    }
                </>
            }
        </>
    );
}

export default NavItem;