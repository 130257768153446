import { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive'

//Components
import CardWrapper from "../mill-comps/components/CardWrapper";
import ProjectItem from "./ProjectItem";

const VerticalProjects = ({projects}) =>{
    const [row1, setRow1] = useState(null);
    const [row2, setRow2] = useState(null);

    useEffect(() =>{
        //Splits the projects array into two halves
        if(projects){   
            const middleIndex = Math.floor(projects.length / 2);
            setRow1(projects.slice(0, middleIndex));
            setRow2(projects.slice(middleIndex));
        }
    }, [projects]);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    });

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    return(
        <>
            {
                isTabletOrMobile ?
                    <div className="px-2 h-auto flex flex-col w-5/6">
                        {
                            projects && projects.map((project) =>(
                                <ProjectItem project={project} key={new Date().toISOString() + Math.random()}/>//Generates a random key
                            ))
                        }
                    </div>
                :

                    <div className="px-2 h-auto grid grid-cols-2 grid-flow-row w-9/12 gap-1">
                        <div className="row1">
                            {
                                //The first row
                                row1 && row1.map((project) =>(
                                    <ProjectItem project={project} key={new Date().toISOString() + Math.random()}/>
                                ))
                            }
                        </div>

                        <div className="row2">
                            {
                                //The first row
                                row2 && row2.map((project) =>(
                                    <ProjectItem project={project} key={new Date().toISOString() + Math.random()}/>
                                ))
                            }
                        </div>
                    </div>
            } 
        </>
    );
}

export default VerticalProjects;