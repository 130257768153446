//Logos
import {cssLogo, digitalOceanLogo, expressLogo, gitLogo, javascriptLogo, linuxLogo, nodeLogo, reactLogo, tailwindLogo, htmlLogo, npmLogo} from '../components/Images';

const Tech = [
  {
    name: "React",
    imgUrl: reactLogo,
    duration: "2 months"
  },
  {
    name: "Express",
    imgUrl: expressLogo,
    duration: "2 months",
  },
  {
    name: "Node",
    imgUrl: nodeLogo,
    duration: "3 months"
  },
  {
    name: "Tailwind",
    imgUrl: tailwindLogo,
    duration: "2 months"
  },
  {
    name: "Git",
    imgUrl: gitLogo,
    duration: "2 months"
  },
  {
    name: "HTML",
    imgUrl: htmlLogo,
    duration: "8 months"
  },
  {
    name: "CSS",
    imgUrl: cssLogo,
    duration: "8 months"
  },
  {
    name: "JavaScript",
    imgUrl: javascriptLogo,
    duration: "8 months"
  },
  {
    name: "Linux",
    imgUrl: linuxLogo,
    duration: "8 months"
  },
  {
    name: "Digital Ocean",
    imgUrl: digitalOceanLogo,
    duration: "8 months"
  },
  {
    name: "NPM",
    imgUrl: npmLogo,
    duration: "2 months"
  }
]

export default Tech;