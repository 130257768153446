import { useEffect } from "react";

//Components
import AppleHeader from "./AppleHeader";
import FullWidthBanner from "./FullWidthBanner";
import BoxWrapper from './mill-comps/components/BoxWrapper';

const About = () =>{
    
    useEffect(() =>{
        document.title = "Clark's Portfolio | About";
    }, []);

    return(
        <div className="about flex flex-col w-full h-full gap-5 py-5">
            <FullWidthBanner className=""/>

            <div className="subGrid w-full grid grid-cols-1 md:grid-cols-2 gap-2">
                {/*Main introduction*/}
                <section className="w-full h-96 px-5">
                    <BoxWrapper className="size-full overflow-clip bg-apple-space-gray">
                        <h3 className="text-apple-light-gradient font-nono font-semibold text-4xl text-left">About Clark Miller</h3>
                        <p className="font-nono text-apple-white lg:text-2xl font-light">As a multifaceted programmer, I hold several titles: peer mentor, Dean's List student, and full-stack developer. I diligently maintain a rigorous study schedule, ensuring that not a single day passes without programming and skill enhancement. This unwavering commitment has earned me a stellar 4.1 GPA and a wealth of knowledge previously beyond my grasp.</p>
                    </BoxWrapper>
                </section>

                <figure className="w-full h-96 px-5">
                    <BoxWrapper className="size-full flex flex-col gap-2">
                        <AppleHeader text1="My  " text2="goals" />
                        <h3 className="text-apple-gray-gradient font-nono font-semibold text-2xl text-left">Programming Dreams</h3>
                        <p className="font-nono lg:text-2xl font-light">My goals for programming are to learn as much as I can at a low-level, make it to a senior dev position, and be proud of who I work for.</p>
                        <h3 className="text-apple-gray-gradient font-nono font-semibold text-2xl text-left">Personal Aspirations</h3>
                        <ul className="font-nono font-light lg:text-xl">
                            <li>- Getting a masters in university</li>
                            <li>- Travelling the World</li>
                            <li>- Getting my own house</li>
                        </ul>
                    </BoxWrapper>
                </figure>

                

                {/*Section about my qualifications*/}
                <aside className="w-full h-96 px-5 ">
                    <BoxWrapper className="size-full">
                        <AppleHeader text1="My  " text2="qualifications" />
                        <p className="font-nono lg:text-2xl font-light">What makes me qualified to do what I do? Well I have a 4.1 GPA, I'm a third term student in the Computer Programming and Analyst program at Fanshawe College, and I push my limits everyday to learn something new and apply it practically.</p>
                    </BoxWrapper>
                </aside>

                {/*Info on my accomplishments*/}
                <article className="w-full h-96 px-5">
                    <BoxWrapper className="size-full bg-apple-space-gray">
                        <h3 className="text-apple-light-gradient font-nono font-semibold text-4xl text-left">My acomplishments</h3>
                        <ul className="font-nono text-apple-white font-light lg:text-2xl">
                            <li>- 4.1 GPA</li>
                            <li>- Deans list</li>
                            <li>- 1st Place in Fanshawe Hackathon</li>
                            <li>- Peer Mentor Learning Commons Award</li>
                            <li>- #1 Google Result and Active Userbase for Website</li>
                        </ul>
                    </BoxWrapper>
                </article>
            </div>
        </div>
    );
}

export default About;