import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive'

//Components
import CardWrapper from '../mill-comps/components/CardWrapper';
import TechList from "./TechList";

const ProjectItem = ({project, className}) =>{
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
    const isSmallMobile = useMediaQuery({ query: '(max-width: 468px)' })

    //Used for setting the length of the description to elipses the additional text for fitting properly on mobile
    const [description, setDescription] = useState(project.description);

    //For ensuring only 3 techs are shown max on mobile
    const [techStack, setTechStack] = useState(project.techStack);

    const truncateArray = (arr) =>{
        if(arr.length > 3){           
            return arr.slice(0, 3);
        }
        return arr;
    } 

    const truncateText = (text) =>{
        if(text.length > 100){
            return text.substring(0, 101) + '...';
        }
        return text;
    }

    useEffect(() =>{
        if(project && isTabletOrMobile){
            setDescription(truncateText(project.description));
            setTechStack(truncateArray(project.techStack));
        }

    }, [project]);

    return(
        <CardWrapper className={`relative shadow-md box-border flex flex-col justify-center items-center w-full h-fit gap-4 ${className} default-view`}>
            <CardWrapper className="transition-all ease-in-out duration-500 opacity-0 hover:opacity-100 flex flex-col items-center absolute backdrop-blur-lg w-full h-full justify-end bg-glass  overflow-hidden ">
                <div className={`hoverContent initialHoverPos absolute flex flex-col h-full w-full items-center justify-end gap-2 py-4 px-2`}>
                    <CardWrapper style={{backgroundColor: "#333336"}}>
                        <p className="font-DM-Sans text-center text-apple-white">{description}</p>
                    </CardWrapper>
                    
                    {!isSmallMobile&&<TechList techStack={techStack}/>}
                    
                    <h4 className="flex justify-center p-2 rounded-2xl text-apple-white text-lg w-1/2 bg-appleBlue hover:bg-appleLightBlue"><a href={project.url } target="_blank">Repo</a></h4>
                </div>
            </CardWrapper>
            
            <img loading="lazy" className="rounded w-full p-2 border-2 shadow-md" src={project.imgUrl}></img>
            <h3 className="text-2xl font-nono text-apple-black font-bold text-apple-gray-gradient">{project.name}</h3>
        </CardWrapper>
    );
}
export default ProjectItem;
