import { useEffect, useState, useMemo } from "react";

//Components
import PreviewItem from "./PreviewItem";


const ItemsPreview = ({className, baseArray, interval, transitionQty, location}) =>{
    const [initialItems, setInitialItems] = useState(null);

    const [displayItems, setDisplayItems] = useState([]);

    useEffect(() =>{ if(baseArray) setInitialItems(baseArray); }, [baseArray]);

    useEffect(() =>{ if(initialItems) setDisplayItems([...initialItems]); }, [initialItems]);

    const moveToStart = (itemsArr) =>{
        const endIndex = itemsArr.length - 1;
        let startIndex;
        if (transitionQty >= itemsArr.length) {
            startIndex = 1;
        } else {
            startIndex = (transitionQty) ? endIndex - transitionQty + 1 : endIndex - 1;
        }
        
        let tempItems = itemsArr.splice(startIndex, endIndex);
        return [...tempItems, ...itemsArr];
    }

    useEffect(() =>{
        const intervalId = setInterval(() => {
            let tempItems = [...displayItems];
            tempItems = moveToStart(tempItems);
            setDisplayItems(tempItems);
        }, (interval) ? interval : 3500);   //Transitions every 3.5 seconds

        return () => clearInterval(intervalId); // Cleanup function to clear the interval
    }, [baseArray, displayItems]);
    
    return(
        <>
            {displayItems&&<div className={`itemsPreview flex justify-center flex-wrap overflow-hidden w-full py-5 h-60 ${className}`} >
                {displayItems?.map((item) =>{
                    const itemLocation = location ? `/${location}/${item.name}` : null;
                    return <PreviewItem key={displayItems?.indexOf(item)} location={itemLocation} item={item}/>
                })}
            </div>}
        </>
    );
}

export default ItemsPreview;