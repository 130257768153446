import { useState, useRef, useEffect } from "react";
import { useMediaQuery } from 'react-responsive'

//Components
import ProjectMenuBtn from "./ProjectMenuBtn";

const ProjectsViewMenu = ({filterEnum, setCurrentFilter, className}) =>{
    const [currentSelection, setCurrentSelection] = useState({ref: null, val: null});

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    const allRef = useRef();
    const frontRef = useRef();
    const backRef = useRef();
    const miscRef = useRef();

    //Checks for changes in the selection
    useEffect(() =>{
        setCurrentFilter(currentSelection);
    }, [currentSelection]);

    //Sets the default button selection
    useEffect(() =>{
        if(allRef){
            setCurrentSelection({ref: allRef, val: filterEnum.all});
        }
    }, [allRef]);

    return(
        <>
            {
                isTabletOrMobile ?
                <div className={`projectsViewMenu flex flex-col items-center justify-center w-full gap-0 text-lg md:text-xl lg:text-2xl ${className}`}>
                    <ProjectMenuBtn className="w-1/2 text-center" currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} refer={allRef} value={filterEnum.all}>All</ProjectMenuBtn>
                    <ProjectMenuBtn className="w-1/2 text-center" currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} refer={frontRef} value={filterEnum.frontEnd}>Front-end</ProjectMenuBtn>
                    <ProjectMenuBtn className="w-1/2 text-center" currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} refer={backRef} value={filterEnum.backEnd}>Back-end</ProjectMenuBtn>
                    <ProjectMenuBtn className="w-1/2 text-center" currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} refer={miscRef} value={filterEnum.misc}>Misc</ProjectMenuBtn>
                </div>:

                <div className={`projectsViewMenu flex flex-row items-center justify-center w-full gap-6 text-sm md:text-lg lg:text-xl ${className}`}>
                    <ProjectMenuBtn currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} refer={allRef} value={filterEnum.all}>All</ProjectMenuBtn>
                    <ProjectMenuBtn currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} refer={frontRef} value={filterEnum.frontEnd}>Front-end</ProjectMenuBtn>
                    <ProjectMenuBtn currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} refer={backRef} value={filterEnum.backEnd}>Back-end</ProjectMenuBtn>
                    <ProjectMenuBtn currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} refer={miscRef} value={filterEnum.misc}>Misc</ProjectMenuBtn>
                </div>
            }
        </>
    );
}

export default ProjectsViewMenu;