
//Components
import NavItem from "./NavItem";

//Icons
import { FaGithub } from "react-icons/fa";


const NavUl = ({className, children}) =>{
    return(
        <nav className={`w-full md:w-fit ${className}`}>
            <ul className="navUl w-full flex flex-row gap-3 justify-evenly items-center">
                {children}
            </ul>
        </nav>
    );
}

export default NavUl;