import { useState } from "react";

//Components
import SocialsBar from "./Socials/SocialsBar";
import FooterRow from "./Footer/FooterRow";

const Footer = () =>{
    const [contactActive, setContactActive] = useState(false);

    return(
        <footer onMouseLeave={() => setContactActive(false)} onMouseEnter={() => setContactActive(true)} className="flex flex-row justify-center items-center w-full h-auto p-5 font-nono font-semibold" style={{backgroundColor: "#333336"}}>
            <FooterRow contactActive={contactActive} headerText="Contact Me">
                <li className="flex items-center text-center"><a href="tel:226-688-4685">(226) 688 - 4685</a></li>
                <li className="flex items-center text-center"><a href="mailto:clarkmillermail@gmail.com">clarkmillermail@gmail.com</a></li>
            </FooterRow>
            <SocialsBar />
        </footer>
    );
}

export default Footer;