//Images
import { atHackathon, wonHackathon, peerTutoringAward, recentHackathon} from '../components/Images';

const Images = [
    {
        name: "1st Place in Hackathon",
        imgUrl: wonHackathon
        },
        {
        name: "Competing in hackathon",
        imgUrl: atHackathon
        },
        {
        name: "Recieving award of recognition for volunteer tutoring",
        imgUrl: peerTutoringAward
        },
        {
        name: "Group photo of most recent student hosted hackathon",
        imgUrl: recentHackathon
        }
    ]


export default Images;