import axios from "axios";

const fetchGet = async (location, token) =>{
    try{
        const response = (token) ? 
            await axios.get(location, {
                headers: {
                Authorization: `${token}`
                },
            }) :  await axios.get(location);
        return response.data;
    }catch(err){
        console.error(err);
    }
}

export {fetchGet};

