import { FaLinkedin, FaGithubSquare } from "react-icons/fa";

import SocialsItem from "./SocialsItem";
const SocialsBar = () =>{

    return(
        <div className="socialsBar shrink w-fit">
            <ul className="w-full flex justify-end">
                <SocialsItem href="https://www.linkedin.com/in/clark-miller-b14718290/"><FaLinkedin /></SocialsItem>
                <SocialsItem href="https://github.com/DevClarkMiller"><FaGithubSquare /></SocialsItem>
            </ul>
        </div>
    );
}

export default SocialsBar;