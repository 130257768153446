
//Components
import CardWrapper from './mill-comps/components/CardWrapper';
import NavUl from "./Nav/NavUl";
import NavItem from "./Nav/NavItem";

//Icons
import { FaGithub } from "react-icons/fa";

const Header = () =>{
    return(
        <CardWrapper className="w-5/6 font-nono font-light" style={{backgroundColor: "#333336"}}>
            <header className="w-full flex flex-row items-center">
                <div className="nameBar hidden lg:flex lg:flex-row lg:items-center lg:justify-center lg:w-fit lg:gap-10">
                    <h1 className="text-apple-white text-3xl select-none text-center">Clark Miller</h1>
                </div>

                <NavUl className="flex justify-center text-apple-white overflow-clip md:flex-1">
                    <NavItem shake location="/">Home</NavItem>
                    <NavItem shake location="/projects">Projects</NavItem>
                    <NavItem shake location="/about">About</NavItem>
                    <NavItem href="https://github.com/DevClarkMiller" shake><FaGithub /></NavItem>
                </NavUl>
            </header>
        </CardWrapper>
    );
}

export default Header;