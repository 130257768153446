import { useEffect, useContext, useState } from "react";


//Components
import ImagesSlideShow from "./ImagesSlideshow";
import AppleHeader from "./AppleHeader";
import ItemsPreview from "./Projects/ItemsPreview";
import BoxWrapper from "./mill-comps/components/BoxWrapper";

//Images
import resumePic from '../Images/resumePic2.png'

//Icons
import { IoMdDownload } from "react-icons/io";

//Context
import { ProjectsContext, ImagesContext, TechSkillsContext } from "../App";

const Content = () =>{
    const [fullProjects, setFullProjects] = useState(null);
    const [fullImages, setFullImages] = useState(null);
    const [fullTechSkills, setFullTechSkills] = useState(null);


    const { projects } = useContext(ProjectsContext);
    const { images } = useContext(ImagesContext);
    const { techSkills } = useContext(TechSkillsContext);

    useEffect(() =>{
        if(images, projects, techSkills){
            setFullProjects(projects);
            setFullImages(images);
            setFullTechSkills(techSkills);
        }
    }, [projects, images, techSkills]);

    useEffect(() =>{ document.title = "Clark's Portfolio | Home"; }, []);

    return(
        <main className="col-flex-center flex-grow p-5 w-full min-h-3.5 gap-5">
            <AppleHeader text1="Introducing" text2="Clark Miller."/>
            {
                fullImages &&
                <span className="flex w-full justify-center lg:grid lg:grid-cols-3">
                    <div className="flex items-center justify-center lg:col-span-2">
                        <ImagesSlideShow className="lg:w-5/6"/>
                    </div>
                    
                    <div className="resumeContainer hidden col-span-1 lg:flex flex-col items-center justify-center gap-3">
                        <AppleHeader text1="In depth" text2="qualifications."/>
                        <img className=" w-1/3 " src={resumePic}></img>
                        <BoxWrapper className="border-2">
                            <a href="https://clarkmiller.ca/publicFiles/resume.pdf" rel="noreferrer" download="clark_miller_resume.pdf" className="flex items-center justify-between gap-5 font-nono font-medium">Download Resume. <IoMdDownload className="text-2xl"/></a>
                        </BoxWrapper>
                    </div>
                    
                </span>
            }

            <AppleHeader text1="The Essential " text2="projects."/>
            {fullProjects&&<ItemsPreview baseArray={fullProjects} transitionQty={1}/>}

            <AppleHeader text1="My " text2="tech knowledge" />
            {techSkills&&<ItemsPreview baseArray={fullTechSkills} interval={1500} transitionQty={3}/>}
        </main>
    );
}

export default Content;