import { useEffect, useState, createContext } from "react";
import { Routes, Route } from "react-router-dom";

//Components
import Header from "./components/Header";
import Content from "./components/Content";
import Projects from "./components/Projects";
import About from "./components/About";
import Footer from "./components/Footer";

//Functions
import { fetchGet } from "./functions/fetch";

//Configs
import Images from "./config/Images";
import TechSkills from "./config/TechSkills";

//Context exports
export const ProjectsContext = createContext();
export const ImagesContext = createContext();
export const TechSkillsContext = createContext();

function App() {
  //State
  const [projects, setProjects] = useState([]);
  const [images, setImages] = useState(Images);
  const [techSkills, setTechSkills] = useState(TechSkills);

  //Imports and sets the projects
  useEffect(() =>{
    const getProjects = async() =>{
      const projects = await fetchGet(process.env.REACT_APP_REST_API_ENDPOINT);  //Projects fetched from local flask REST API
      if(!projects) return;

      console.log(`Recieved ${projects.length} parsed repos!`);
      setProjects(projects);
    }

    getProjects();
  }, []);

  return (
    <div className="App flex-grow min-h-screen col-flex-center justify-between  bg-apple-white">
      <ProjectsContext.Provider value={{projects, setProjects}}>
        <ImagesContext.Provider value={{images, setImages}}>
          <TechSkillsContext.Provider value={{techSkills, setTechSkills}}>
            <Header />
            <Routes>
              <Route path="/" element={<Content />}/>
              <Route path="/projects" element={<Projects />}/>
              <Route path="/about" element={<About />}/>
            </Routes>
            <Footer />
          </TechSkillsContext.Provider>
        </ImagesContext.Provider>
      </ProjectsContext.Provider>
    </div>
  );
}

export default App;
