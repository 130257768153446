
//Components
import CardWrapper from "../mill-comps/components/CardWrapper";
import TechItem from "./TechItem";
const TechList = ({techStack}) =>{
    return(
        <ul className="techList justify-center flex flex-row flex-wrap gap-2"> 
                {
                    techStack.map((tech) =>(
                        <TechItem key={techStack.indexOf(tech)} tech={tech}/>
                    ))
                }
        </ul>
    );
}
export default TechList;